

// 2. Include any default variable overrides here
$ex-dark-blue: #201a4f;
$ex-middle-blue: #1e6cb2;
$ex-light-blue: #bcd9f0;
$ex-dark-grey: #575757;
$ex-light-grey: #dadada;

$primary: $ex-dark-blue;
$secondary: $ex-middle-blue;
$info: $ex-light-blue;
$light: $ex-light-grey;
$dark: $ex-dark-grey;

$body-color: $ex-dark-grey;

$border-width: 2px;

$font-family-sans-serif: 'Titillium Web', sans-serif;
$font-family-base: 'Titillium Web', sans-serif;


$border-radius: 0 rem !default;
$border-radius-lg: 0 rem !default;
$border-radius-sm: 0 rem !default;

$table-border-color: $ex-light-blue;
$table-border-width: $ex-light-blue;


// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/bootstrap";



body{
    font: $font-family-sans-serif;
    color: $dark;
    width: 100vw;
    font-size: 20px;
}

h1{
    color: $secondary;
}

a {
    color: $secondary
}

a:hover {
    color: $info;
}

input {
    color: $secondary;
    
    font-size: 2.4rem;
    border: px dashed $ex-light-grey;
}


*::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $light;
}

*:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $light;
    opacity: 1;
}

*::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $light;
    opacity: 1;
}

*:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $light
}

*:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
    color: $light
}

button {
    color: #095480;
background-color:#fff;
    border: 0 none;
    cursor: pointer;
    font-size: 2.45vw;
    height: 3.05vw;
    width: 3.05vw;
}

button:hover {
    color: $light;
}


#box {
    display: inline-block;
    width: 10rem;
    height: 10rem;
    
    border: 0.5rem dashed $light;
    color: $dark;
    padding: 1rem;
    text-align: center;
    
    cursor: pointer;
}

#box:hover {
    color: #095480;
}



.clear {
    clear: both;
}

#info {
    margin-top: 1vw;
}

#input {
    margin-top: 2vw;
    padding-top: 2vw;
    border-top: 0.05vw dotted #b9b3ac;
}

#inputtext {
    width: 32vw;
    float: left;
}

#codeform {
    float: right;
}

#footer {
    position: relative;
    text-align: center;
    
    
    padding: 40px 20px 20px 20px;
    margin:auto;
    color: #b9b3ac;
}

.cssload-tetrominos {
    position: relative;
    left: 3.4rem;
    top: 4.6rem;
    transform: translate(-2.95rem, -2.5rem);
    -o-transform: translate(-2.95rem, -2.5rem);
    -ms-transform: translate(-2.95rem, -2.5rem);
    -webkit-transform: translate(-2.95rem, -2.5rem);
    -moz-transform: translate(-2.95rem, -2.5rem);
}

.cssload-tetromino {
    width: 2.5rem;
    height: 2.95rem;
    position: absolute;
    transition: all ease 0.35s;
    -o-transition: all ease 0.35s;
    -ms-transition: all ease 0.35s;
    -webkit-transition: all ease 0.35s;
    -moz-transition: all ease 0.35s;
    background: url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23095480" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23fff" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center;
}

.cssload-box1 {
    animation: cssload-tetromino1 1.73s ease-out infinite;
    -o-animation: cssload-tetromino1 1.73s ease-out infinite;
    -ms-animation: cssload-tetromino1 1.73s ease-out infinite;
    -webkit-animation: cssload-tetromino1 1.73s ease-out infinite;
    -moz-animation: cssload-tetromino1 1.73s ease-out infinite;
}

.cssload-box2 {
    animation: cssload-tetromino2 1.73s ease-out infinite;
    -o-animation: cssload-tetromino2 1.73s ease-out infinite;
    -ms-animation: cssload-tetromino2 1.73s ease-out infinite;
    -webkit-animation: cssload-tetromino2 1.73s ease-out infinite;
    -moz-animation: cssload-tetromino2 1.73s ease-out infinite;
}

.cssload-box3 {
    animation: cssload-tetromino3 1.73s ease-out infinite;
    -o-animation: cssload-tetromino3 1.73s ease-out infinite;
    -ms-animation: cssload-tetromino3 1.73s ease-out infinite;
    -webkit-animation: cssload-tetromino3 1.73s ease-out infinite;
    -moz-animation: cssload-tetromino3 1.73s ease-out infinite;
    z-index: 2;
}

.cssload-box4 {
    animation: cssload-tetromino4 1.73s ease-out infinite;
    -o-animation: cssload-tetromino4 1.73s ease-out infinite;
    -ms-animation: cssload-tetromino4 1.73s ease-out infinite;
    -webkit-animation: cssload-tetromino4 1.73s ease-out infinite;
    -moz-animation: cssload-tetromino4 1.73s ease-out infinite;
}



@keyframes cssload-tetromino1 {
    0%, 40% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(1.25rem, -0.7rem);
    }
    60%, 100% {
        transform: translate(2.5rem, 0);
    }
}

@-o-keyframes cssload-tetromino1 {
    0%, 40% {
        -o-transform: translate(0, 0);
    }
    50% {
        -o-transform: translate(1.25rem, -0.7rem);
    }
    60%, 100% {
        -o-transform: translate(2.5rem, 0);
    }
}

@-ms-keyframes cssload-tetromino1 {
    0%, 40% {
        -ms-transform: translate(0, 0);
    }
    50% {
        -ms-transform: translate(1.25rem, -0.7rem);
    }
    60%, 100% {
        -ms-transform: translate(2.5rem, 0);
    }
}

@-webkit-keyframes cssload-tetromino1 {
    0%, 40% {
        -webkit-transform: translate(0, 0);
    }
    50% {
        -webkit-transform: translate(1.25rem, -0.7rem);
    }
    60%, 100% {
        -webkit-transform: translate(2.5rem, 0);
    }
}

@-moz-keyframes cssload-tetromino1 {
    0%, 40% {
        -moz-transform: translate(0, 0);
    }
    50% {
        -moz-transform: translate(1.25rem, -0.7rem);
    }
    60%, 100% {
        -moz-transform: translate(2.5rem, 0);
    }
}

@keyframes cssload-tetromino2 {
    0%, 20% {
        transform: translate(2.5rem, 0px);
    }
    40%, 100% {
        transform: translate(3.8rem, 0.7rem);
    }
}

@-o-keyframes cssload-tetromino2 {
    0%, 20% {
        -o-transform: translate(2.5rem, 0px);
    }
    40%, 100% {
        -o-transform: translate(3.8rem, 0.7rem);
    }
}

@-ms-keyframes cssload-tetromino2 {
    0%, 20% {
        -ms-transform: translate(2.5rem, 0px);
    }
    40%, 100% {
        -ms-transform: translate(3.8rem, 0.7rem);
    }
}

@-webkit-keyframes cssload-tetromino2 {
    0%, 20% {
        -webkit-transform: translate(2.5rem, 0px);
    }
    40%, 100% {
        -webkit-transform: translate(3.8rem, 0.7rem);
    }
}

@-moz-keyframes cssload-tetromino2 {
    0%, 20% {
        -moz-transform: translate(2.5rem, 0px);
    }
    40%, 100% {
        -moz-transform: translate(3.8rem, 0.7rem);
    }
}

@keyframes cssload-tetromino3 {
    0% {
        transform: translate(3.8rem, 0.7rem);
    }
    20%, 60% {
        transform: translate(2.5rem, 1.4rem);
    }
    90%, 100% {
        transform: translate(1.25rem, 0.7rem);
    }
}

@-o-keyframes cssload-tetromino3 {
    0% {
        -o-transform: translate(3.8rem, 0.7rem);
    }
    20%, 60% {
        -o-transform: translate(2.5rem, 1.4rem);
    }
    90%, 100% {
        -o-transform: translate(1.25rem, 0.7rem);
    }
}

@-ms-keyframes cssload-tetromino3 {
    0% {
        -ms-transform: translate(3.8rem, 0.7rem);
    }
    20%, 60% {
        -ms-transform: translate(2.5rem, 1.4rem);
    }
    90%, 100% {
        -ms-transform: translate(1.25rem, 0.7rem);
    }
}

@-webkit-keyframes cssload-tetromino3 {
    0% {
        -webkit-transform: translate(3.8rem, 0.7rem);
    }
    20%, 60% {
        -webkit-transform: translate(2.5rem, 1.4rem);
    }
    90%, 100% {
        -webkit-transform: translate(1.25rem, 0.7rem);
    }
}

@-moz-keyframes cssload-tetromino3 {
    0% {
        -moz-transform: translate(3.8rem, 0.7rem);
    }
    20%, 60% {
        -moz-transform: translate(2.5rem, 1.4rem);
    }
    90%, 100% {
        -moz-transform: translate(1.25rem, 0.7rem);
    }
}

@keyframes cssload-tetromino4 {
    0%, 60% {
        transform: translate(1.25rem, 0.7rem);
    }
    90%, 100% {
        transform: translate(0, 0);
    }
}

@-o-keyframes cssload-tetromino4 {
    0%, 60% {
        -o-transform: translate(1.25rem, 0.7rem);
    }
    90%, 100% {
        -o-transform: translate(0, 0);
    }
}

@-ms-keyframes cssload-tetromino4 {
    0%, 60% {
        -ms-transform: translate(1.25rem, 0.7rem);
    }
    90%, 100% {
        -ms-transform: translate(0, 0);
    }
}

@-webkit-keyframes cssload-tetromino4 {
    0%, 60% {
        -webkit-transform: translate(1.25rem, 0.7rem);
    }
    90%, 100% {
        -webkit-transform: translate(0, 0);
    }
}

@-moz-keyframes cssload-tetromino4 {
    0%, 60% {
        -moz-transform: translate(1.25rem, 0.7rem);
    }
    90%, 100% {
        -moz-transform: translate(0, 0);
    }
}
 
